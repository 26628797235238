import React from "react";
import { Flex, Media } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection } from "@ryerson/frontend.layout";
import { ButtonLink } from "@ryerson/frontend.navigation";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { CareersButtons } from "@components/Shared/CareersButtons/CareersButtons";

type Slide = {
	title: string;
	description: string;
	imageUrl: string;
	actionLabel?: string | string[];
	actionUrl?: string;
};

export type ImageTextContent = {
	slides: Slide[];
};

export type ImageTextProps = {
	content: ImageTextContent;
	isCareers?: boolean;
};

const SlideLeft = styled.div`
	width: calc(50% - 20px);
	display: inline-block;
	vertical-align: top;
	margin-right: 20px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				width: 100%;
				display: block;
				margin-right: 0px;
			}
		`;
	}}
`;

const SlideRight = styled.div`
	width: calc(50% - 20px);
	display: inline-block;
	vertical-align: top;
	margin-left: 20px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				width: 100%;
				display: block;
				margin-left: 0px;
			}
		`;
	}}
`;

const SlideText = styled.div`
	width: 80%;
	max-width: 460px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				width: 100%;
				max-width: 100%;
			}
		`;
	}}
`;

const SlideButtonLinkContainer = styled.div`
	margin-top: 60px;
	display: block;
	width: 100%;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media (max-width: ${theme.breakpoints.lg}) {
				margin-top: 24px;
			}
		`;
	}}
`;

const Spacer = styled.div`
	display: block;
	height: 20px;
	width: 100%;
`;

const ImageText: React.FC<ImageTextProps> = ({ content, isCareers = false }) => {
	const { theme } = useTheme();

	const hasEnvCareersUrls =
		isCareers &&
		Boolean(process.env.GATSBY_CAREERS_URL_USA && process.env.GATSBY_CAREERS_URL_CANADA);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				{content.slides.map((slide: Slide, index: number) => {
					return (
						<>
							{index % 2 === 0 ? (
								<>
									<ContentSection vPadding="120px" type="secondary">
										<SlideLeft theme={theme}>
											<img
												src={slide.imageUrl}
												css={css`
													width: 100%;
													height: auto;
												`}
											/>
										</SlideLeft>
										<SlideRight theme={theme}>
											<SlideText
												css={css`
													margin-left: 17.66666%;
												`}
												theme={theme}
											>
												<Typography
													variant="h2"
													css={css`
														margin-bottom: 25px;
														letter-spacing: -2px;
													`}
													type="secondary"
												>
													{slide.title}
												</Typography>
												<Typography
													size="md"
													type="secondary"
													css={css`
														display: block;
														max-width: 360px;
													`}
												>
													{slide.description}
												</Typography>
												{!slide.actionUrl ? (
													<></>
												) : (
													<>
														{hasEnvCareersUrls && (
															<Flex
																css={css`
																	margin-top: 25px;
																`}
															>
																<CareersButtons />
															</Flex>
														)}
														{!hasEnvCareersUrls && (
															<SlideButtonLinkContainer theme={theme}>
																<ButtonLink
																	to={slide.actionUrl}
																	label={
																		!slide.actionLabel
																			? ""
																			: slide.actionLabel
																	}
																	type="secondary"
																/>
															</SlideButtonLinkContainer>
														)}
													</>
												)}
											</SlideText>
										</SlideRight>
									</ContentSection>
								</>
							) : (
								<>
									<ContentSection vPadding="120px" type="primary">
										<SlideRight theme={theme}>
											<SlideText theme={theme}>
												<Typography
													variant="h2"
													css={css`
														margin-bottom: 25px;
														letter-spacing: -2px;
													`}
													type="primary"
												>
													{slide.title}
												</Typography>
												<Typography
													size="md"
													type="primary"
													css={css`
														display: block;
														max-width: 360px;
													`}
												>
													{slide.description}
												</Typography>
												{!slide.actionUrl ? (
													<></>
												) : (
													<>
														{hasEnvCareersUrls && (
															<Flex
																css={css`
																	margin-top: 25px;
																`}
															>
																<CareersButtons />
															</Flex>
														)}
														{!hasEnvCareersUrls && (
															<SlideButtonLinkContainer theme={theme}>
																<ButtonLink
																	to={slide.actionUrl}
																	label={
																		!slide.actionLabel
																			? ""
																			: slide.actionLabel
																	}
																	type="primary"
																/>
															</SlideButtonLinkContainer>
														)}
													</>
												)}
											</SlideText>
										</SlideRight>
										<SlideLeft theme={theme}>
											<img
												src={slide.imageUrl}
												css={css`
													width: 100%;
													height: auto;
												`}
											/>
										</SlideLeft>
									</ContentSection>
								</>
							)}
						</>
					);
				})}
			</Media>
			<Media lessThan="lg">
				<Spacer />
				{content.slides.map((slide: Slide, index: number) => {
					return (
						<ContentSection vPadding="30px" type="primary">
							<SlideLeft theme={theme}>
								<img
									src={slide.imageUrl}
									css={css`
										width: 100%;
										height: auto;
									`}
								/>
							</SlideLeft>
							<SlideRight theme={theme}>
								<SlideText theme={theme}>
									<Typography
										variant="h3"
										css={css`
											letter-spacing: -2px;
											margin-top: 25px;
											margin-bottom: 21px;
										`}
										type="primary"
									>
										{slide.title}
									</Typography>
									<Typography size="md" type="primary">
										{slide.description}
									</Typography>
									{!slide.actionUrl ? (
										<></>
									) : (
										<>
											{hasEnvCareersUrls && (
												<Flex
													css={css`
														margin-top: 25px;
													`}
												>
													<CareersButtons />
												</Flex>
											)}
											{!hasEnvCareersUrls && (
												<SlideButtonLinkContainer theme={theme}>
													<ButtonLink
														to={slide.actionUrl}
														label={
															!slide.actionLabel
																? ""
																: slide.actionLabel
														}
														type="primary"
													/>
												</SlideButtonLinkContainer>
											)}
										</>
									)}
								</SlideText>
							</SlideRight>
						</ContentSection>
					);
				})}
			</Media>
		</>
	);
};

export default ImageText;
